import React, { useCallback } from 'react';

import classnames from 'classnames';
import styles from './package-toggle.module.css';

const PackageToggle = ({ isActive, setIsActive, options }) => {
	const renderToggle = useCallback(
		({ text, short, icon }, isActive) => {
			return (
				<span
					key={text}
					className={classnames(styles.toggle, {
						[styles.active]: isActive
					})}
				>
					<button
						type="button"
						className={styles.clear}
						onClick={(_) => setIsActive(text)}
					>
						<div className="d-flex justify-content-center align-items-center">
							<h3 className="d-inline d-sm-none mb-0 mt-1">{short}</h3>
							<h3 className="d-none d-sm-inline mb-0 mt-1">{text}</h3>
							{icon && <img src={icon} className={styles.peacemaker} />}
						</div>
					</button>
				</span>
			);
		},
		[setIsActive]
	);

	return (
		<>
			<div className={styles.toggleWrapper} style={{ whiteSpace: 'nowrap' }}>
				{options.map((option) => renderToggle(option, isActive === option.text))}
			</div>
		</>
	);
};
export default PackageToggle;


import React, { useEffect, useMemo, useState } from 'react';

import Constants from '../../../constants/api';
import InfoIconPopup from '../../shared/info-icon-popup/info-icon-popup';
import { Link } from 'gatsby';
import PopupChannels from '../../shared/popup-channels';
import az from '../../../images/packages/a_z.svg';
import classnames from 'classnames';
import filmov from '../../../images/packages/filmov.svg';
import filmovExtra from '../../../images/packages/filmov+.svg';
import mladejki from '../../../images/packages/mladejki.svg';
import mladejkiExtra from '../../../images/packages/mladejkiExtra.svg';
import nachalo from '../../../images/packages/nachalo.svg';
import nachaloExtra from '../../../images/packages/nachalo+.svg';
import net100 from '../../../images/packages/net100.svg';
import net1000 from '../../../images/packages/net1000.svg';
import net200 from '../../../images/packages/net200.svg';
import net300 from '../../../images/packages/net300.svg';
import net400 from '../../../images/packages/net400.svg';
import net50 from '../../../images/packages/net50.svg';
import net600 from '../../../images/packages/net600.svg';
import { packageType } from '../../../constants/packages';
import peacemaker from '../../../images/index/peacemaker-badge.png';
import sporten from '../../../images/packages/sporten.svg';
import styles from './package.module.css';
import tv110 from '../../../images/packages/tv110.svg';
import tv170 from '../../../images/packages/tv170.svg';
import tv200 from '../../../images/packages/tv200.svg';
import tv200sport from '../../../images/packages/tv200sport.svg';
import tv70 from '../../../images/packages/tv70.svg';
import { validateField } from '../../shared/form-popup';

const Package = ({ packageInfo, extend, setExtend, btnId, hideChannels }) => {
	const [showChannels, setShowChannels] = useState(false);
	const [isBusinessPage, setIsBusinessPage] = useState(false);

	const isTvPackage = !packageInfo.isOnlyInternet;
	const isAltCheckout = true;

	const {
		showMostOrderedBadge,
		textMostOrdered,
		mostOrderedBgColor,
		mostOrderedTextColor,
		showPromoBadge,
		textPromoOrdered,
		promoOrderedBgColor,
		promoOrderedTextColor,
		originalPrice,
		currentPrice,
		showInformationButton,
		informationButtonText,
		promotionalText,
		showPromoPackageBadge,
		promoBadgeInfoTooltip,
		promoBadgeBgColor,
		promoBadgeTextColor,
		textPromoBadge,
		tvChannels,
		hdChannels,
		internetSpeedNumber,
		hdFetcherInfo,
		packageFeatures,
		ontFetcher,
		ontFetcherInfo,
		ontFetcherColor,
		ontFetcherHeader,
		staticIp,
		packageExtras,
		pauses,
		includedChannelImages,
		title,
		internalTitle
	} = packageInfo;

	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.location.pathname.includes('za-biznesa')) {
				setIsBusinessPage(true);
			}
		}
	}, []);

	const priceStyle = useMemo(() => {
		return originalPrice.toString().length > 2
			? styles.mainPriceSmall
			: styles.mainPrice;
	}, [originalPrice]);

	const mostOrderedBadge = useMemo(() => {
		if (showMostOrderedBadge) {
			return (
				<div className="text-dark-blue">
					<div
						className={`bg-mint  ${styles.topGreenBanner}`}
						style={{
							top: showPromoBadge ? 150 : 20,
							backgroundColor: mostOrderedBgColor,
							color: mostOrderedTextColor
						}}
					>
						{textMostOrdered}
					</div>
				</div>
			);
		}
	}, [showMostOrderedBadge, showMostOrderedBadge]);

	const promoBadge = useMemo(() => {
		if (showPromoBadge) {
			return (
				<div
					className={`${styles.topPurpleBanner}`}
					style={{
						backgroundColor: promoOrderedBgColor,
						color: promoOrderedTextColor
					}}
				>
					{textPromoOrdered}
				</div>
			);
		}
	}, [showPromoBadge, textPromoOrdered]);
	const packageHeader = useMemo(() => {
		switch (title) {
			case packageType.tv:
				return {
					additionalInfo: false,
					logo: packageLogo(internalTitle),
					logoHeight: 40,
					leftValue: tvChannels,
					leftText: 'Канали',
					middleValue: '',
					middleText: '',
					rightValue: hdChannels,
					rightText: 'HD'
				};
			case packageType.internet:
				return {
					additionalInfo: false,
					logo: packageLogo(internalTitle),
					logoHeight: 40,
					leftValue: internetSpeedNumber,
					leftText: 'Mbps',
					middleValue: '',
					middleText: '',
					...parseOntFetcher(ontFetcher, ontFetcherHeader)
				};
			default:
				return {
					additionalInfo: true,
					logo: packageLogo(internalTitle),
					logoHeight: 40,
					leftValue: internetSpeedNumber,
					leftText: 'Mbps',
					middleValue: tvChannels,
					middleText: 'Канали',
					rightValue: hdChannels,
					rightText: 'HD'
				};
		}
	}, [
		title,
		tvChannels,
		hdChannels,
		internetSpeedNumber,
		ontFetcher,
		internetSpeedNumber,
		isBusinessPage
	]);

	return (
		<>
			<div className={styles.packageCard}>
				<div>
					<div
						className="rounded-top"
						style={{
							backgroundColor: isBusinessPage ? '#1b2d69' : '#151F41'
						}}
					>
						<div className={styles.packageLogoContainer}>
							<img
								height={packageHeader.logoHeight}
								src={packageHeader.logo}
							/>
						</div>
					</div>

					<div className="position-relative">
						<div>{promoBadge}</div>
						<div>{mostOrderedBadge}</div>

						{!packageHeader.additionalInfo && (
							<div
								className="d-flex justify-content-center"
								style={{
									paddingTop: 5,
									paddingBottom: 5,
									marginRight: 9
								}}
							>
								<div
									className={styles.leftContainer}
									style={{ marginTop: isTvPackage ? 5 : 0 }}
								>
									<div
										className={classnames('', {
											h5: !isTvPackage,
											h4: isTvPackage
										})}
										style={{ margin: 0 }}
									>
										{packageHeader.leftValue}
									</div>
									<div style={{ fontSize: 14, fontWeight: 500 }}>
										{packageHeader.leftText}
									</div>
								</div>
								<div
									className="border-left border-dark"
									style={{
										height: isTvPackage ? 50 : 45,
										marginTop: 4,
										marginBottom: 4
									}}
								></div>
								<div
									className={
										isTvPackage
											? styles.rightContainer
											: styles.rightContainerSM
									}
								>
									<div style={{ color: ontFetcherColor }}>
										<span
											className={classnames('', {
												h5: ontFetcherHeader
											})}
										>
											{packageHeader.rightValue}
										</span>
									</div>
									<div
										className={
											isTvPackage ? styles.textSM : styles.textXS
										}
									>
										{packageHeader.rightText}
									</div>
								</div>
							</div>
						)}
						{packageHeader.additionalInfo && (
							<div
								className="d-flex justify-content-center"
								style={{
									backgroundColor: '#151F41',
									marginBottom: 10
								}}
							>
								<div
									className={styles.leftContainer}
									style={{
										color: 'white',
										paddingRight: 0,
										paddingBottom: 3
									}}
								>
									<div className="h5" style={{ margin: 0 }}>
										{packageHeader.leftValue}
									</div>
									<div style={{ fontSize: 14, fontWeight: 500 }}>
										{packageHeader.leftText}
									</div>
								</div>
								<div
									className={styles.leftContainer}
									style={{ backgroundColor: 'white', paddingLeft: 0 }}
								>
									<div className="h5" style={{ margin: 0 }}>
										{packageHeader.middleValue}
									</div>
									<div style={{ fontSize: 14, fontWeight: 500 }}>
										{packageHeader.middleText}
									</div>
								</div>
								<div
									className="border-left border-dark"
									style={{ height: 35, marginTop: 4, marginBottom: 4 }}
								></div>
								<div
									style={{
										backgroundColor: 'white',
										flexGrow: 1,
										paddingLeft: 0
									}}
									className={
										isTvPackage
											? styles.rightContainer
											: styles.rightContainerSM
									}
								>
									<div className="h5" style={{ margin: 0 }}>
										{packageHeader.rightValue}
									</div>
									<div
										className={
											isTvPackage ? styles.textSM : styles.textXS
										}
									>
										{packageHeader.rightText}
									</div>
								</div>
							</div>
						)}

						{showPromoBadge && (
							<>
								{currentPrice ? (
									<div className={styles.pricingSection}>
										<div className={styles.pricingContainer}>
											<strike
												className={classnames('text-danger h2')}
											>
												<span className="strike-color">
													{currentPrice}
												</span>
											</strike>
											<span className="strike-color small">
												лева/
												<br />
												месец
											</span>
										</div>
									</div>
								) : (
									<div style={{ height: 0 }}></div>
								)}
								<div className={styles.pricingSection}>
									<div
										className={styles.pricingContainerMain}
										style={{ backgroundColor: promoOrderedBgColor }}
									>
										<span className={priceStyle}>
											{originalPrice}
										</span>
										<span className={styles.priceMeta}>
											лева/
											<br />
											месец
										</span>
										{promotionalText && (
											<InfoIconPopup
												popupText={promotionalText}
												className="ml-2"
											/>
										)}
									</div>
									<div
										className={classnames(
											styles.packageFreeAdditionContainer,
											{
												[styles.fullOpacity]: showPromoPackageBadge
											}
										)}
										style={{
											backgroundColor: promoBadgeBgColor,
											color: promoBadgeTextColor
										}}
									>
										{showPromoPackageBadge && (
											<>
												<span>{textPromoBadge}</span>
												<InfoIconPopup
													popupText={promoBadgeInfoTooltip}
													className="ml-1"
													small
												/>
											</>
										)}
									</div>
								</div>
							</>
						)}

						{!showPromoBadge && (
							<>
								<div className={styles.pricingSection}>
									<div className={styles.pricingContainer}>
										<span className={classnames('strike-color h2')}>
											{originalPrice}
										</span>
										<span className="strike-color small">
											лева/
											<br />
											месец
										</span>
										{showInformationButton && (
											<InfoIconPopup
												popupText={informationButtonText}
												className="ml-2"
											/>
										)}
									</div>
								</div>
								{showPromoPackageBadge && (
									<div className={styles.pricingSection}>
										<div
											className={classnames(
												styles.packageFreeAdditionContainer,
												{
													[styles.fullOpacity]: showPromoPackageBadge
												}
											)}
											style={{
												backgroundColor: promoBadgeBgColor,
												color: promoBadgeTextColor
											}}
										>
											<>
												<span>{textPromoBadge}</span>
												<InfoIconPopup
													popupText={promoBadgeInfoTooltip}
													className="ml-1"
													small
												/>
											</>
										</div>
									</div>
								)}
							</>
						)}

						{isTvPackage && (
							<div className={styles.packageFeaturesContainer}>
								{!hideChannels && (
									<div className={styles.listItemContainer}>
										{includedChannelImages && (
											<span className={styles.listItemCheck} />
										)}
										<span className={styles.listItemText}>
											<div className="row">
												{includedChannelImages &&
													includedChannelImages.map(
														({ icon, height }) => (
															<span>
																<img
																	style={{
																		marginLeft: '2px'
																	}}
																	height={height}
																	src={icon}
																	alt="Включени канали"
																/>
															</span>
														)
													)}
											</div>
										</span>
									</div>
								)}

								<div className={styles.listItemContainer}>
									<span className={styles.listItemCheck} />
									<b className={styles.listItemText}>ТB канали</b>
									<span
										small
										className={classnames(
											'icon usp-info-icon small',
											styles.infoIconChannels
										)}
										role="button"
										target="0"
										onClick={() => {
											setShowChannels(true);
										}}
									/>
								</div>
								{hdFetcherInfo && (
									<div className={styles.listItemContainer}>
										<span className={styles.listItemCheck} />
										<div className={styles.listItemText}>
											<div>
												<b>Aurora приемник</b>
											</div>
											<b>Aurora приложение</b>
										</div>

										<InfoIconPopup popupText={hdFetcherInfo} small />
									</div>
								)}

								{packageFeatures.map(
									({ active, text, renderHtml }, index) => {
										return (
											<div
												className={classnames(
													styles.listItemContainer,
													{
														[styles.noOpacity]: !active
													}
												)}
												key={index}
											>
												<span className={styles.listItemCheck} />
												{!renderHtml && (
													<span className={styles.listItemText}>
														{text}
													</span>
												)}
												{renderHtml && (
													<span
														className={styles.listItemText}
														dangerouslySetInnerHTML={{
															__html: text
														}}
													/>
												)}
											</div>
										);
									}
								)}
							</div>
						)}

						{internetSpeedNumber && (
							<div className={styles.packageFeaturesContainer}>
								<div className={styles.listItemContainer}>
									<img
										src={peacemaker}
										className="img-fluid usp-icon mr-2"
										style={{
											width: 25,
											height: 30
										}}
									/>
									<span className={styles.listItemText}>
										Сигурност с
										<br /> <b>Peacemaker</b>
									</span>
									<InfoIconPopup
										popupText={
											'Благодарение на DNS защитата от Whalebone, ние сме в готовност да отблъснем всякакъв вид атаки, на които дори и най-добрите антивирусни програми и защитни стени не могат да противодействат.'
										}
										className={'ml-2'}
										small
									/>
								</div>
								
								{ontFetcher && (
									<div className={styles.listItemContainer}>
										<span
											className={classnames(
												styles.listItemCheck,
												styles.listItemCheckBlue
											)}
										/>
										<span className={styles.listItemText}>
											<b>{ontFetcher}GHz</b> оптичен
											<br />
											wi-fi рутер
										</span>
										<InfoIconPopup
											popupText={ontFetcherInfo}
											className={'ml-2'}
											small
										/>
									</div>
								)}

								{staticIp && (
									<div className={styles.listItemContainer}>
										<span
											className={classnames(
												styles.listItemCheck,
												styles.listItemCheckBlue
											)}
										/>
										<span className={styles.listItemText}>
											<b>Статично IP</b>
										</span>
									</div>
								)}
								{!isTvPackage && (
									<div>
										{pauses !== 0 && (
											<div className={styles.listItemContainer}>
												<span
													className={classnames(
														styles.listItemCheck,
														styles.listItemCheckBlue
													)}
												/>
												<span className={styles.listItemText}>
													<b>{pauses} паузи по договор</b>
												</span>
											</div>
										)}
									</div>
								)}
							</div>
						)}

						{extend && packageExtras && packageExtras.length > 0 && (
							<div className={styles.packageFeaturesContainer}>
								<span
									className={classnames(
										styles.packageFeaturesTile,
										styles.packageFeaturesTilePurple
									)}
								>
									{'Доп. Екстри'}
								</span>

								{packageExtras.map(({ text, renderHtml }, index) => {
									return (
										<div
											className={styles.listItemContainer}
											key={index}
										>
											<span
												className={classnames(
													styles.listItemCheck,
													styles.listItemCheckPurple
												)}
											/>
											{!renderHtml && (
												<span className={styles.listItemText}>
													{text}
												</span>
											)}
											{renderHtml && (
												<span
													className={styles.listItemText}
													dangerouslySetInnerHTML={{
														__html: text
													}}
												/>
											)}
										</div>
									);
								})}
							</div>
						)}

						<ChoosePackage
							packageInfo={packageInfo}
							btnId={btnId}
							isFull={isTvPackage}
							isAltCheckout={isAltCheckout}
							isBusinessPage={isBusinessPage}
						/>
					</div>

					<div className="text-center">
						<span
							className={styles.seeAllDetailsToggle}
							onClick={() => setExtend(!extend)}
						>
							{extend ? 'Скрий' : 'Виж всички детайли'}
						</span>
					</div>
				</div>
			</div>

			{isTvPackage && (
				<PopupChannels
					show={showChannels}
					setShow={(v) => {
						setShowChannels(v);
					}}
					channelId={packageInfo.channelId}
				/>
			)}
		</>
	);
};

const ChoosePackage = ({ packageInfo, btnId, isFull, isAltCheckout, isBusinessPage }) => (
	<div className={styles.choosePackageWrapper}>
		{!isAltCheckout && (
			<Link
				to={'/checkout/'}
				state={{
					selectedPackageId: packageInfo.id,
					packageTvCount: packageInfo.hdFetcher
				}}
				className={
					'my-4 btn ' +
					(packageInfo.isPrimaryPackage
						? 'btn-primary green'
						: 'btn-secondary-no-outline')
				}
				id={
					isFull
						? 'pcta-package-' + (btnId + 1)
						: 'pcta-package-' + (btnId + 1) + '-net'
				}
			>
				Избери пакет
			</Link>
		)}

		{isAltCheckout && (
			<>
				<button onClick={toggleNext} className={`btn btn-primary green btn-mid`}>
					Заяви по телефон
				</button>
				<CallBooking packageInfo={packageInfo} isBusinessPage={isBusinessPage} />
			</>
		)}
	</div>
);

const toggleNext = (e) => {
	e.target.style.display = 'none';
	e.target.nextElementSibling.style.display = 'block';
};

const CallBooking = ({ packageInfo, isBusinessPage }) => {
	const telephone = isBusinessPage ? '088 299 1596' : '088 299 1617';

	return (
		<>
			<div className="mt-3" style={{ display: 'none' }}>
				<p style={{ fontWeight: 500 }}>
					Моля оставете
					<br /> телефон за връзка:
				</p>
				<form onSubmit={(e) => sendCallBooking(e, packageInfo, isBusinessPage)}>
					<div className="mb-3 input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">+359</span>
						</div>
						<input type="text" className="form-control" />
					</div>
					<button type="submit" className="mt-0 mb-3 btn btn-primary green">
						Изпрати
					</button>
				</form>
				или се обадете на:
				<a
					href={`tel:${telephone}`}
					className="mt-3 mb-4 btn btn-primary dark-blue"
				>
					{telephone}
				</a>
			</div>

			<div className={styles.formConfirmation} style={{ display: 'none' }}>
				<p>Благодарим Ви!</p>
				<p>Ще се свържем с Вас по телефон до 48 часа.</p>
			</div>
		</>
	);
};

const sendCallBooking = (e, packageInfo, isBusinessPage) => {
	e.preventDefault();
	const formWrapper = e.target.parentElement;
	const telephone = e.target.querySelector('input').value;

	if (validateField('telephone', telephone)) {
		let send_to = isBusinessPage
			? 'business_sales@netsurf.bg'
			: 'callcenter@net-surf.net';
		const subject = 'Заявка за обратна връзка';
		const body =
			'Заявка за обратна връзка<br>Продукт: ' +
			packageInfo.internetSpeed +
			' + ' +
			packageInfo.title +
			'<br>Телефон: +359 ' +
			telephone;

		const emailObj = {
			send_to: send_to,
			subject: subject,
			body: body
		};

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const POSToptions = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(emailObj)
		};

		const request = new Request(Constants.MAIL_URL, POSToptions);
		fetch(request).then((res) => {
			if (res.status === 200) {
				formWrapper.style.display = 'none';
				formWrapper.nextElementSibling.style.display = 'block';
			}
		});

		formWrapper.style.display = 'none';
		formWrapper.nextElementSibling.style.display = 'block';
	} else {
		formWrapper.querySelector('input').classList.add('is-invalid');
	}
};

const parseOntFetcher = (ontFetcher, ontFetcherHeader) => {
	if (ontFetcherHeader) {
		return {
			rightValue: `${ontFetcherHeader}`,
			rightText: ''
		};
	}

	return {
		rightValue: `${ontFetcher}GHz`,
		rightText: 'оптичен wi-fi рутер'
	};
};

export default Package;

function packageLogo(internalTitle) {
	switch (internalTitle.toLowerCase()) {
		case 'net 50':
			return net50;
		case 'net 100':
			return net100;
		case 'net 200':
			return net200;
		case 'net 400':
			return net400;
		case 'net 300':
			return net300;
		case 'net 600':
			return net600;
		case 'net 1000':
			return net1000;
		case 'net 200m':
			return mladejki;
		case 'net 500m':
			return mladejkiExtra;
		case 'auroratv 210+':
			return tv200sport;
		case 'auroratv 200+':
			return tv200;
		case 'auroratv 170+':
			return tv170;
		case 'auroratv 110+':
			return tv110;
		case 'auroratv 70+':
			return tv70;
		case 'начало':
			return nachalo;
		case 'филмов':
			return filmov;
		case 'спортен':
			return sporten;
		case 'начало екстра':
			return nachaloExtra;
		case 'филмов екстра':
			return filmovExtra;
		case 'от а до я':
			return az;
	}
}

